@use "variables" as *;
@use "theme" as *;
@use "breakpoint" as *;

// Variables
$headerHeight: 60px;
$headerHeightMobile: 80px;
$footerHeight: 60px;

$tileWidth: 287px;
$fontColorGray: #888;

$largeScreen: 1230px 999999px;
$scrMobile: 0px 640px;
$scrMobileSmall: 0px 460px;
$scrTablet: 0px 1000px;

$padding: 8px;

// Mixin for text elements
@mixin label {
    font-family: $font;
    //-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

// Mixin for whole screen
@mixin whole-screen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

// Background animation for hovering. Used for buttons
@mixin hoverAnim($color) {
    transition-property: background-color;
    transition-duration: 0.2s;

    &:hover {
        background-color: lighten($color, 10%);
    }
}

.field {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;

  & > input {
    margin-bottom: 0;
  }

  & > oex-tui-editor {
    margin-bottom: 0;
  }

  & > oex-tree {
    margin-bottom: 0;
  }

  & > mat-select {
    margin-bottom: 0;
  }

  & > ng-select {
    margin-bottom: 0;
  }

  @include breakpoint($scr-tablet) {
    margin-bottom: 20px;
  }
}

@mixin controls-invalid-styles {
  // Default controls
  input.ng-touched.ng-invalid, mat-select.ng-touched.ng-invalid, textarea.ng-touched.ng-invalid {
    border-color: $c-accent;
  }

  // NG Select
  ng-select.ng-touched.ng-invalid::ng-deep > .ng-select-container {
    border-color: $c-accent !important;
  }

  // Checkbox
  mat-checkbox.ng-touched.ng-invalid::ng-deep .mat-checkbox-frame {
    border-color: $c-accent !important;
  }

  // TUI Editor
  oex-tui-editor.ng-touched.ng-invalid::ng-deep > .toastui-editor-defaultUI {
    border-color: $c-accent;
  }

  // Image upload
  oex-img-upload.ng-touched.ng-invalid::ng-deep > .preview {
    border-color: $c-accent;
  }
}
