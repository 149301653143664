@use "sass:color";
@use "breakpoint" as *;
@use "variables" as *;
@use "txt" as *;
@use "var" as *;

$bodyColor: #FFF;
$themeColor: #24292e;
$hilightColor: #f1f8ff;
$linkColor: rgb(3, 102, 214);

$gap-l: 64px;
$gap-m: 42px;
$gap-s: 28px;
$gap-xs: 20px;
$searchHeight: 60px;

$scr-width: 1290px;
$ctrl-height: 58px;
$tablet-gap: 54px;
$mobile-gap: 20px;
$portal-sidebar-width: 306px;
$portal-bottom-space: 120px;
$portal-bottom-space-mobile: 100px;
$grid-max-title-width: 350px;
//$portal-actions-height: 80px;

$border-color: #86939e;

$hoverTime: 0.2s;

// New design theme
// Colors
$c-black: #151414;
$c-black80: #444343;
$c-black40: #a1a1a1;
$c-black20: rgba(230, 230, 230, 0.2);
$c-off-white: #F8F9F9;

$c-gray-bg: #e4e4e6;
$c-gray-border: #D1D5D4;
$c-gray-border2: #ECF0F1;
$c-blue-dark: #39444E;
$c-gray2: #B2B2B2;
$c-gray3: #909090;


$c-primary: #C75000;
@if $pd == 0 {
  $c-primary: #00B2A9;
}
$c-primary-highlight: #fbf1eb;
@if $pd == 0 {
  $c-primary-highlight: #ebfaf9;
}
$c-highlight: #5668FF;


$c-deepblue: #2F2A95;
$c-blue12: #e6e5f2;
$c-blue20: #d5d4ea;
$c-darkblue: #1E1974;

$c-link: $c-primary; //#C75000;
$c-link-hover: #AE4600;

$c-lightgreen: color.adjust($c-primary, $lightness: 10%);
$c-green: $c-primary;
$c-darkgreen: $c-link-hover;

@if $pd == 0 {
  $c-link: #00B2A9; //#C75000;
  $c-link-hover: #008F88;

  $c-lightgreen: #11EDDC;
  $c-green: #00B2A9;
  $c-darkgreen: $c-link;
}

$c-lightgray: #e6e6e6;
$c-white: #fafafa;
$c-gray: #fafafa;
$c-gray-menu-hover: #F6F7FA;



$c-blue-pd: #27318A;
$c-accent: #EF697F;

// Variables
$radius: 8px;
// $font: 'Oxygen';
$input-height: 40px;
$p30: 30px;

$c-main: $c-primary;
@if $pd == 0 {
  $c-main: #00B2A9;
}

@mixin theme-module {

  :root {
    --white: #FFF;
    --black: #151414;
    --grey-1: #D1D5D4;
    --grey-2: #B2B2B2;
    --porcelain: #ECF0F1;
    --off-white: #F8F9F9;
    --blue-dark: #39444E;
    --blue-darker-tone: #1F212E;
    --orange: #C75000;
    --orange-hover: #AE4600;
    @if $pd == 0 {
      --orange: #00B2A9;
      --orange-hover: #008F88;
      --Orange: #00B2A9;
      --Orange-hover: #008F88;
      --Orange-Hover: #008F88;
      --Orange-rgb: 0, 178, 169;
    }

    --neon-blue: #5668FF;
    --green: #00B2A9;
    --red: #EF697F;
    --red-bg: rgba(239, 105, 127, 0.10);
    --flt-red: brightness(0) invert(64%) sepia(67%) saturate(2616%) hue-rotate(309deg) brightness(95%) contrast(97%);
    --flt-blue-dark: brightness(0) saturate(100%) invert(26%) sepia(12%) saturate(681%) hue-rotate(167deg) brightness(94%) contrast(96%);
    --flt-black: brightness(0) saturate(100%) invert(2%) sepia(4%) saturate(817%) hue-rotate(316deg) brightness(96%) contrast(87%);
    --flt-orange: brightness(0) saturate(100%) invert(36%) sepia(32%) saturate(3208%) hue-rotate(4deg) brightness(90%) contrast(103%);
    --Flt-Orange: brightness(0) saturate(100%) invert(36%) sepia(32%) saturate(3208%) hue-rotate(4deg) brightness(90%) contrast(103%);

    @if $pd == 0 {
      --flt-orange: brightness(0) saturate(100%) invert(44%) sepia(96%) saturate(1914%) hue-rotate(145deg) brightness(98%) contrast(101%);
      --Flt-Orange: brightness(0) saturate(100%) invert(44%) sepia(96%) saturate(1914%) hue-rotate(145deg) brightness(98%) contrast(101%);
    }
    --flt-orange-hover: brightness(0) saturate(100%) invert(26%) sepia(94%) saturate(1301%) hue-rotate(5deg) brightness(96%) contrast(101%);
    @if $pd == 0 {
      --flt-orange-hover: brightness(0) saturate(100%) invert(32%) sepia(37%) saturate(4661%) hue-rotate(158deg) brightness(93%) contrast(101%);
    }
    --flt-neon-blue: brightness(0) saturate(100%) invert(45%) sepia(77%) saturate(5198%) hue-rotate(225deg) brightness(105%) contrast(106%);
    --flt-green: brightness(0) saturate(100%) invert(44%) sepia(96%) saturate(1914%) hue-rotate(145deg) brightness(98%) contrast(101%);
    --flt-green-hover: brightness(0) saturate(100%) invert(32%) sepia(37%) saturate(4661%) hue-rotate(158deg) brightness(93%) contrast(101%);
    --flt-white: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(237deg) brightness(105%) contrast(107%);
  }

  // Theme for material components

  // Box shadow for select dropdown
  .mat-select-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12) !important;
  }

  // Radio button style
  mat-radio-button {
    font-family: $font;
    font-size: 16px;
    font-weight: normal;
    color: $c-black;

    & .mat-radio-outer-circle {
      border: 1px solid $c-lightgray;
    }


    /*  &.mat-radio-checked {
        & .mat-radio-outer-circle {
          border-color: $c-lightgreen;
        }

        & .mat-radio-inner-circle {
          background-color: $c-lightgreen;
        }
      }*/
  }

  // Checked style
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $c-lightgreen;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $c-lightgreen;
  }
  // Hide hover and material circle
  .mat-radio-button .mat-radio-ripple {
    display: none;
  }

  // Disabled style
  .mat-radio-button.disabled .mat-radio-outer-circle {
    border-color: $c-lightgray !important;
  }
  .mat-radio-button.disabled .mat-radio-inner-circle {
    background-color: $c-lightgray !important;
  }


  // material menu
  .mat-mdc-menu-item {
    min-height: 40px;

    & * {
      font-family: $font;
      font-size: 14px;
    }
  }
  .mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    --mat-menu-item-label-text-font: #{$font};
    --mat-menu-item-label-text-size: 14px;
    font: $font;
    font-size: 14px;
    color: var(--black);

    & > img {
      transform: translateY(2px);
      margin-right: 4px;
    }
  }
  .mat-mdc-menu-small .mat-mdc-menu-item {
    min-height: 28px;

  }
}

// Cover whole screen
@mixin whole-screen {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
}

// Input controls
@mixin input-control{
  background: var(--white, #FFF);
  -webkit-appearance: none;
  @include txt-14;
  padding: 10px 20px;
  height: $input-height;
  border: 1px solid var(--grey-1, #D1D5D4);
  border-radius: $radius;
  outline: none;


  &[disabled] {
    background-color: transparent;
    opacity: 0.5;
    background: var(--off-white, #F8F9F9);
  }

  &::placeholder {
    @include txt-14;
    color: var(--grey-3, #909090);
  }

  &:focus {
    border: 1px solid $c-black;
  }
}

// Mixin for whole screen components
@mixin screen {
  width: $scr-width;
  @include breakpoint($scr-tablet) {
    width: 100%;
  }
}

// Mixin for editing forms
@mixin editing-form {
  // Title
  & h4 {
    display: inline-block;
    margin-bottom: 24px;
    font-weight: 400;

    @include breakpoint($scr-tablet) {
      margin-bottom: 20px;
    }
  }

  // Style for all inputs
  & input, & textarea {
    @include input-control;
    width: 100%;
    margin-bottom: 24px;

    @include breakpoint($scr-tablet) {
      margin-bottom: 20px;
    }

    // Remove arrows from date picker
    &::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
    }

    //// Invalid input
    //&:invalid {
    //  border: 1px solid #EF697F;
    //}
  }

  & ng-select {
    width: 100%;
    margin-bottom: 24px;
    @include breakpoint($scr-tablet) {
      margin-bottom: 20px;
    }
  }

  & textarea {
    height: 140px;
  }

  // Style for al labels
  & label {
    display: block;
    width: 100%;
    margin-bottom: 6px;
    @include txt-14;
    font-weight: 700;
  }
}

@mixin button {
  user-select: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: $font;
  text-decoration: none;
  padding: 6px 10px;
  border-radius: 4px;
  transition: background-color, background 0.2s linear;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;

  &[disabled], &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &[fake-disabled], &.fake-disabled {
    opacity: 0.5;
    cursor: default;
  }
}

// Green buttons
@mixin button-green {
  @include button;
  color: white;
  height: 40px;
  background-color: $c-primary;

  @if $pd == 1 {
    background-color: $c-primary;
  }

  &:hover {
    background-color: $c-link-hover;
    @if $pd == 1 {
      background-color: $c-link-hover;
    }
  }

  &:active {
    background-color: $c-darkgreen;
    @if $pd == 1 {
      background-color: #2F2A95;
    }
  }
}

// Gray buttons
@mixin button-gray {
  @include button;
  height: 40px;
  border: none;
  font-style: normal;
  font-weight: 400;
  text-align: center;

  border-radius: 4px;
  background: var(--off-white, #F8F9F9);
  color: var(--blue-dark, #39444E);
  /* Body 14 regular */
  font-size: 14px;
  line-height: 20px;

  & > img {
    margin-right: 10px;
  }

  &:hover {
    background: var(--porcelain, #ECF0F1);
  }

  &:active {
    background: var(--porcelain, #ECF0F1);
  }
}

@mixin button-light-green {
  @include button;
  color: $c-lightgreen;
  background-color: white;
  border: 1px solid $c-lightgreen;
  border-radius: 4px;

  &:hover {
    color: $c-green;
    border: 1px solid $c-green;
  }
  //
  //&:active {
  //  background-color: $c-darkgreen;
  //}
}

// Green buttons
@mixin button-blue {
  @include button;
  color: $c-primary;
  border: 1px solid $c-primary;
  border-radius: 4px;
  background-color: white;

  &:hover {
    background-color: lighten($c-primary, 50%);
  }

  &:active {
    background-color: lighten($c-primary, 50%);
  }
}

@mixin icon-highlight {
  filter: saturate(10) hue-rotate(-187deg) brightness(1.4);
}

// Headline 1
@mixin headline1 {
  margin-top: 20px;
  font-family: $font;
  display: inline-block;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  vertical-align: middle;
}

@mixin title-with-button {
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 24px;
    @include breakpoint($scr-tablet) {
      margin-bottom: 20px;
    }

    & > h2 {
      font-family: $font;
      font-size: 24px;
    }

    & > .btn-green {
      @include button-green;
      min-width: 180px;
      height: 50px;
    }
  }
}

@keyframes loading-anim-keys {
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

@mixin loading-anim {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loading-anim-keys ;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f2f2f2 38%, #fafafa 54%);
  background-size: 1000px 640px;
}

// Hover item for select
//.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
//  background-color: #F6F7FA !important;
//}

@mixin mobile-bottom-panel{
  @include breakpoint($scr-mobile) {
    position: fixed;
    background-color: white;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-left: none;
    border-right: none;
    border-bottom: none;
    transition: top 0.2s linear;
    padding: 16px;
    padding-top: 32px;
    &.expanded {
      top: 93px;
      overflow-y: scroll;
      z-index: 4;
    }
  }
}


@mixin editing-modal {
  & > input {
    width: 400px;
  }

  & textarea, & .mp-markdown {
    width: 776px;
    height: 156px;
    margin-bottom: 0;
    padding: 20px 12px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    outline: none;
  }

  & oex-tabs {
    margin-bottom: 10px;
    margin-top: 0;
    &:after {
      background: transparent;
    }
  }

  & .mp-markdown {
    margin-bottom: 7px !important;
    overflow-y: scroll;
  }
}
