@use "variables" as *;
@use "theme" as *;

@mixin markdown {
  .markdown {
    color: $c-black;
    font-family: $font;
    font-size: 16px;
    line-height: 1.5;

    & img {
      max-width: 100%;
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      border: none;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    & > h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      border: none;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    // Links
    a {
      text-decoration: none !important;
      color: $c-link !important;

      &:hover {
        color: $c-link-hover !important;
      }

      &:visited {
        text-decoration: none !important;
      }
    }

    // Lists
    ul, ol {
      padding-left: 1em;
      list-style-position: inside;
      margin-bottom: 16px;
      display: block;
      list-style-type: disc;
    }

    // Code blocks
    code {
      display: block;
      padding: 16px;
      overflow: auto;
      font-size: 80%;
      line-height: 1.45;
      background-color: #f6f8fa;
      border-radius: 3px;
    }

    pre {
      margin-top: 0;
      margin-bottom: 16px;
    }

    p {
      margin-top: 0;
      margin-bottom: 16px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .md-tasklist {
    padding-left: 1em !important;
  }

  .md-tasklist-item::marker {
    -webkit-appearance: none;
    content: "";
    /*display: inline-block;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 50%;
    content: " ";
    margin-left: 0;
    margin-top: 0;
    border-radius: 0;
    height: 18px;
    width: 18px;
    position: absolute;
    left: 0;
    top: 1px;
    cursor: pointer;*/
    // border: 1px solid #ccc;
    //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAEgAAAACaqbJVAAAAQklEQVQ4EWM8c+bMfwYqABaQGcbGxhQZdfbsWQYmikxA0jxqEFJg4GCOhhGOgEESHg0jpMDAwRx8YQQuj0DlCaUAAEdBCPJ7TaEPAAAAAElFTkSuQmCC);
    display: none;
  }

  .md-tasklist-checkbox {
    padding-left: 1em;
    position: relative;

    &:before {
      content: ' ';
      display: inline-block;
      position: absolute;
      left: -0.5em;
      top: 0;
      background-color: white;
      border: 1px solid #ccc;
      width: 18px;
      height: 18px;
      box-sizing: border-box;
    }

    &.checked:before {
      border: none;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAEgAAAACaqbJVAAAA1ElEQVQ4EWP0nvbsPwMVABMVzAAbMWoQIiT5OJgYvLS5EAJQFguGCB4BkCHt/kIM8kKsYFXbrn6DqyY6sJENefjuN8ORuz/ghoAYWA0COR2kEQbQDanc+I7h049/MGkwjVANFQYZkmXHD/YCyABiDAFpxQgjkJO9dbjA4QAKDxAAhQnIO9hcAlYAJDBcBHIySANII8gAYgwBGYZhEEgQZFjVJohhhFwCUg8CjPgyLT8nE8N/YJZGD1iIVlQSI4yQpT9+R40ZZDl0NlavoSsihj/4DAIAR+hZHUj727YAAAAASUVORK5CYII=)
    }
  }
}
