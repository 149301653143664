@use "txt" as *;
@use "variables" as *;

@mixin ui-form {

  .form,
  .ui-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 24px;

    &>h3 {
      font-weight: normal;
    }

    &>section {
      display: grid;
      grid-template-columns: 1fr;
      padding: 16px;
      border-radius: 8px;
      background: var(--Off-White, #F8F9F9);
      width: 100%;
      gap: 20px;
    }

    &.frm-1-cols,
    & .frm-1-cols {
      grid-template-columns: 1fr;
      gap: 0;
      column-gap: 0;
      row-gap: 20px;
    }

    &.frm-2-cols,
    & .frm-2-cols {
      grid-template-columns: 1fr 1fr;
    }

    &.frm-3-cols,
    & .frm-3-cols {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.frm-4-cols,
    & .frm-4-cols {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &.frm-5-cols,
    & .frm-5-cols {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &>* {
      min-width: 0;
    }
  }



  .control,
  .ui-control {
    @include txt-14;
    font-weight: 700;

    // Label
    &>label {
      display: inline-block;
      margin-bottom: 6px;

      // Info icon for tooltip
      &>.ui-icon-info {
        cursor: help;
        display: inline-block;
        transform: translateY(2px);
        margin-left: 6px;
      }
    }

    // Input
    &>input {
      width: 100%;
    }

    // Checkbox
    &>input[type=checkbox]:first-child {
      vertical-align: middle;
      margin-right: 8px;

      &+label {
        font-weight: normal;
      }
    }

    // Required "*" sign
    &.required {
      &>label:after {
        content: "*";
        display: inline-block;
        color: var(--Required-Sign);
        margin-left: 6px;
      }
    }

    // Info icon for tooltip
    &>.ui-icon-info {
      cursor: help;
      display: inline-block;
      transform: translateY(2px);
      margin-left: 6px;
    }
  }

  .ctrl-span-2 {
    grid-column: 1/span 2;
  }

  .ctrl-span-3 {
    grid-column: 1/span 3;
  }

  .ctrl-span-4 {
    grid-column: 1/span 4;
  }

  .ctrl-span-5 {
    grid-column: 1/span 5;
  }

  // Styles for required controls

  // Default controls
  input.ng-touched.ng-invalid,
  textarea.ng-touched.ng-invalid {
    border-color: var(--Error);
  }

  // NG Select
  ng-select.ng-touched.ng-invalid > .ng-select-container {
    border-color: var(--Error) !important;
  }

  // Checkbox
  /*mat-checkbox.ng-touched.ng-invalid::ng-deep .mat-checkbox-frame {
  border-color: $c-accent !important;
}*/

  // TUI Editor
  ui-tui-editor.ng-touched.ng-invalid>.toastui-editor-defaultUI {
    border-color: var(--Error);
    ;
  }

  // Image upload
  /*oex-img-upload.ng-touched.ng-invalid::ng-deep > .preview {
  border-color: $c-accent;
}*/

  /*.ui-form-title {
  font-family: var($font);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: var(--Black, #151414);
}*/

  .ui-form-actions {
    display: flex;
    gap: 10px;
    margin-top: 48px;
  }

}
