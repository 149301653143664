@use "variables" as *;

@mixin txt-12 {
  color: var(--black, #151414);
  font-family: $font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@mixin txt-13 {
  color: var(--black, #151414);
  font-family: $font;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@mixin txt-14 {
  color: var(--black, #151414);
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin txt-16 {
  color: var(--black, #151414);
  font-family: $font;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@mixin txt-18 {
  color: var(--black, #151414);
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
