@use "@ng-select/ng-select/themes/default.theme.css" as *;
@use "txt" as *;
@use "variables" as *;

@mixin ui-ng-select {
  $height: 40px;

  // Adjust height
  ng-select {
    // height: 40px;
    min-height: $height;

    &.ui-small {
      min-height: 36px;
    }

    & .ng-select-container {
      border-radius: 8px !important;
      border-color: var(--grey-1, #D1D5D4) !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &.ng-select-focused {
      & .ng-select-container {
        border-color: var(--black, #151414) !important;
      }
    }

    // Change border for opened control
    &.ng-select-opened {
      &>.ng-select-container {
        border: 1px solid var(--black, #151414) !important;
      }

      // For dropdown items make edge same color as focused
      & .ng-dropdown-panel.ng-select-top {
        border-bottom: 1px solid var(--black, #151414);
      }

      // For dropdown items make edge same color as focused
      & .ng-dropdown-panel.ng-select-bottom {
        border-top: 1px solid var(--black, #151414);
      }
    }


    &.transparent {
      &>.ng-select-container {
        border: none !important;
        background: transparent !important;
      }

      &.ng-select-opened {
        &>.ng-select-container {
          border: none !important;

        }

        & .ng-dropdown-panel {
          border-radius: 4px !important;
          overflow: hidden;
        }

        // For dropdown items make edge same color as focused
        & .ng-dropdown-panel.ng-select-top {
          border-bottom: 1px solid var(--Grey-1);
        }

        // For dropdown items make edge same color as focused
        & .ng-dropdown-panel.ng-select-bottom {
          border-top: 1px solid var(--Grey-1);
        }
      }
    }

    &.small {

      //&.ng-select-opened {
      &>.ng-select-container {

        //border: none !important;
        /*height: 20px !important;
        max-height: 20px !important;
        min-height: 20px !important;*/
        &>.ng-value-container {
          padding-left: 10px;
          //height: 27px;
        }
      }

      //}
    }
  }


  // Adjust height and paddings
  .ng-select .ng-select-container {
    // height: $height;
    min-height: $height;
    padding: 5px 0;
    box-sizing: border-box;

    display: flex;
    align-items: flex-end;
  }

  // Adjust height
  .ng-select.ng-select-single .ng-select-container {
    min-height: $height;
  }

  // Adjust height for small size
  .ng-select.ng-select-single.ui-small .ng-select-container {
    min-height: 36px;
  }


  // Adjust font
  .ng-select {
    font-family: $font;

    & input {
      height: auto;
      @include txt-14;
      border-radius: 0;
    }
  }

  // Remove paddings
  .ng-select input {
    padding: initial;
    padding-left: 12px !important;
  }

  // Value
  .ng-select.ng-select-single .ng-select-container .ng-value-container {
    padding-top: 1px;
  }

  // Input
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 10px;
    // top: 14px;
  }

  // Placeholder
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 9px;
    @include txt-14;
    color: var(--grey-3, #909090);
    padding-left: 15px;
  }

  // Option style
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    @include txt-14;
  }

  // Selected item row
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: white;
  }

  // Selected item badge inside input
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--grey-1, #D1D5D4);
    @include txt-14;
  }

  // No border for items in transparent select
  .ng-select.ng-select-multiple.transparent .ng-select-container .ng-value-container .ng-value {
    border: none;
  }


  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: var(--off-white);
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: none;
    border-radius: 0 4px 4px 0;

    color: transparent;
    background-repeat: no-repeat;
    background-image: url(../../images/delete-input.svg);
    padding-right: 10px;
    background-position: 50% 50%;
  }

  // Badge with multi select
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 0;
    padding-left: 5px;
    gap: 5px;
  }

  // Badge value
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
  }


  .ng-select .ng-select-container {
    align-items: stretch;
  }

  .ng-select .ng-arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    padding-right: 14px;

    &>.ng-arrow {
      border: none;
      width: 19px !important;
      height: 15px !important;
      background-repeat: no-repeat;
      background-image: url('../../images/arrow-down-select.svg');
      transition: transform 0.2s linear;
    }

    /*&.small {
    height: 30px;
  }*/
  }


  // Arrow in small dropdown
  .ng-select.small .ng-arrow-wrapper {
    padding-right: 0;
    width: 24px;
  }

  // Hide combo input in small dropdown
  .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    display: none;
  }

  // Fix whitespace for small dropdown selected items
  .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    white-space: normal;
  }

  // Make drop panel fit text width for small dropdowns
  ng-select.small.ng-select-opened .ng-dropdown-panel {
    width: auto;
  }

  // Opened state arrow
  .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: 0;
    border: none;
    transform: scaleY(-1);
  }

  // Clear button
  .ng-select .ng-clear-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px !important;

    // Clear icon
    &>.ng-clear {
      // transform: translateY(-2px);
      color: transparent;
      background-repeat: no-repeat;
      background-image: url(../../images/delete-input.svg);
      padding-right: 10px;
      background-position: 50% 50%;
    }
  }

  // Clear icon hover state
  .ng-select .ng-clear-wrapper:hover .ng-clear {
    color: transparent;
    filter: var(--flt-orange);
  }


  .ng-select .ng-select-container .ng-value-container {
    padding-left: 20px;
  }

  // Size of selected item
  .ng-select.ng-select-single .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    @include txt-14;
  }

  // Add style for option item in multiple mode
  .ng-dropdown-panel.ng-select-multiple {
    & .ng-option {
      &:before {
        display: inline-block;
        content: "";
        vertical-align: middle;
        margin-right: 10px;
        border-radius: 2px;
        transition: border-color 0.2s linear;
        border: 1.5px solid var(--Grey-1, #D1D5D4) !important;
        width: 18px !important;
        height: 18px !important;
        transform: translateY(-1px);
      }

      &.ng-option-selected:before {
        border: 1.5px solid var(--Orange) !important;
        background-image: url(../../images/checkbox-check.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 24px;
        filter: var(--Flt-Orange);
      }

      &:hover:before {
        border: 1.5px solid var(--Black) !important;
      }
    }
  }
}