@use "theme" as *;
@use "variables" as *;
@use "txt" as *;

@mixin ui-ng-select {
  $height: 40px;
  // Adjust height
  ng-select {
    // height: 40px;
    min-height: $height;

    & .ng-select-container {
      border-radius: 8px !important;
      border-color: var(--grey-1, #D1D5D4) !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &.ng-select-focused {
      & .ng-select-container {
        border-color: var(--black, #151414) !important;
      }
    }

    // Change border for opened control
    &.ng-select-opened {
      & > .ng-select-container {
        border: 1px solid var(--black, #151414) !important;
      }

      // For dropdown items make edge same color as focused
      & .ng-dropdown-panel.ng-select-top {
        border-bottom: 1px solid var(--black, #151414);
      }

      // For dropdown items make edge same color as focused
      & .ng-dropdown-panel.ng-select-bottom {
        border-top: 1px solid var(--black, #151414);
      }
    }
  }


  // Adjust height and paddings
  .ng-select .ng-select-container {
    // height: $height;
    min-height: $height;
    padding: 5px 0;
    box-sizing: border-box;

    display: flex;
    align-items: flex-end;
  }

  // Adjust height
  .ng-select.ng-select-single .ng-select-container {
    min-height: $height;
  }

  // Adjust font
  .ng-select {
    font-family: $font;

    & input {
      height: auto;
      @include txt-14;
      border-radius: 0;
    }
  }

  // Remove paddings
  .ng-select input {
    padding: initial;
    padding-left: 12px !important;
  }

  // Value
  .ng-select.ng-select-single .ng-select-container .ng-value-container {
    padding-top: 1px;
  }

  // Input
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 10px;
    // top: 14px; TODO: different cases?
  }

  // Placeholder
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 9px;
    @include txt-14;
    color: var(--grey-3, #909090);
    padding-left: 15px;
  }

  // Option style
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    @include txt-14;
  }

  // Selected item row
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: white;
  }

  // Selected item badge inside input
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--grey-1, #D1D5D4);
    @include txt-14;
  }


  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: var(--off-white);
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: none;
    border-radius: 0 4px 4px 0;

    color: transparent;
    background-repeat: no-repeat;
    background-image: url(/assets/img/delete-input.svg);
    padding-right: 10px;
    background-position: 50% 50%;
  }

  // Badge with multi select
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 0;
    padding-left: 5px;
    gap: 5px;
  }

  // Badge value
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
  }


  .ng-select .ng-select-container {
    align-items: stretch;
  }

  .ng-select .ng-arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    padding-right: 14px;

    & > .ng-arrow {
      border: none;
      width: 19px !important;
      height: 15px !important;
      background-repeat: no-repeat;
      background-image: url('/assets/img/arrow-down-select.svg');
      transition: transform 0.2s linear;
    }
  }
  // Opened state arrow
  .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    top: 0;
    border: none;
    transform: scaleY(-1);
  }

  // Clear button
  .ng-select .ng-clear-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px !important;
    // Clear icon
    & > .ng-clear {
      // transform: translateY(-2px);
      color: transparent;
      background-repeat: no-repeat;
      background-image: url(/assets/img/delete-input.svg);
      padding-right: 10px;
      background-position: 50% 50%;
    }
  }
  // Clear icon hover state
  .ng-select .ng-clear-wrapper:hover .ng-clear {
    color: transparent;
    filter: var(--flt-orange);
  }


  .ng-select .ng-select-container .ng-value-container {
    padding-left: 20px;
  }

  // Size of selected item
  .ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    @include txt-14;
  }

  .ng-spinner-loader {
    margin-top: 5px;
  }
}
