@mixin scroll-pager {
  .scroll-snap-x {
    scroll-snap-type: x mandatory;
  }

  .scroll-snap-y {
    scroll-snap-type: y mandatory;
  }

  .scroll-snap-item {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
}