@use "variables" as *;
@use "txt" as *;

@mixin ui-md {
  .ui-md {

    & ul,
    & ol {
      margin-left: 20px;
    }

    & a {
      font-family: $font;
      color: var(--Orange);
      text-decoration: none;
      transition: color $hover-time linear;
      will-change: color;
      cursor: pointer;

      &.text {
        color: var(--Black);
      }

      &:hover {
        color: var(--Orange-hover);
      }
    }
  }
}