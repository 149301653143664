@mixin ui-animations {
  .ui-anim-fade-in {
    animation: key-fade-in 0.2s linear;
  }

  .ui-anim-fade-out {
    animation: key-fade-out 0.2s linear;
    animation-fill-mode: forwards;
  }

  .ui-anim-scale-in {
    animation: key-scale-in 0.2s linear;
    animation-fill-mode: forwards;
  }

  .ui-anim-scale-in-tooltip {
    animation: key-scale-in-tooltip 0.1s linear;
    animation-fill-mode: forwards;
  }

  @keyframes key-fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes key-fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      pointer-events: none;
    }
  }

  @keyframes key-scale-in {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes key-scale-in-tooltip {
    0% {
      opacity: 0;
      transform: scale(0) translateX(-50%);
    }

    100% {
      opacity: 1;
      transform: scale(1) translateX(-50%);
    }
  }
}